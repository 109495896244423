import { FC, useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { GroupBase, OptionsOrGroups } from "react-select";

import AddCasePatientInformation from "../addCase/addCasePatientInformation";
import AddCasePatientAddress from "../addCase/addCasePatientAddress";
import AddCasePatientInsurances from "../addCase/addCasePatientInsurances";
import HubInputField from "../inputType";

import useFetch from "src/hooks/useFetch";

import { APIEndpoints } from "src/types/apiTypes";
import { CaseStepTwoProps, PatientDetailType, PatientDropdownResponse, PracticeDropdownResponse, ProviderDropdownResponse } from "./types";
import { addPatientDetails, resetDoctorAndPatientDetails, resetPatientDetails } from "src/helpers/addCase";
import { LoaderContext } from "src/context/loaderContext";



const CaseStepTwo: FC<CaseStepTwoProps> = ({ control, isVisible, getValues, watch, setValue, trigger }): JSX.Element => {
  const [prevPatientId, setPrevPatientId] = useState(getValues("patient_attributes.global_patient_id"));
  const [firstPracticeId] = useState(getValues("doctor_attributes.practice_id"));

  const { setLoader, hideLoader } = useContext(LoaderContext);

  const practiceId = watch("doctor_attributes.practice_id");
  const doctorId = watch("doctor_attributes.global_doctor_id");
  const globalPatientId = watch("patient_attributes.global_patient_id");





  /**
   * @getPracticeDropdown api to get practice based listing
   * @first initialize the useFetch() hook
   * @second trigger practice listing api to get listing
   */
  const {data: practiceDropdownApiRes } = useFetch<PracticeDropdownResponse>(APIEndpoints.PRACTICE_DROPDOWN);


  const { data: globalDoctorsApiResponse, mutate: getGlobalDoctorsListing } =
    useFetch<ProviderDropdownResponse>(APIEndpoints.PROVIDER_DROPDOWN);

  /** @second */
  useEffect(() => {
    if (practiceId && practiceDropdownApiRes) {
      if (practiceId !== firstPracticeId)
        resetDoctorAndPatientDetails(setValue);

      const globalDoctorParams = {
        practice_id: practiceId,
      };

      getGlobalDoctorsListing(null, null, globalDoctorParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceId, practiceDropdownApiRes]);





 /**
   * @getPatientListingByDoctorId api to get patient listing from doctor id
   * @first initialize the useFetch() hook
   * @second calling patient api using selected doctor id
   * @third patient api success handler
   */
  const { error: patientListingApiError, data: patientListingApiResponse, mutate: getPatientListing } =
    useFetch<PatientDropdownResponse>(APIEndpoints.PATIENT_DROPDOWN);

  /** @second */
  useEffect(() => {
  if (practiceId && doctorId && globalDoctorsApiResponse) {
    const patientQueryParams = {
      practice_id: practiceId,
    };

    getPatientListing(null, null, patientQueryParams);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorId, globalDoctorsApiResponse]);

  /** @third */
  useEffect(() => {
    if (patientListingApiResponse || patientListingApiError)
      setValue("patient_attributes.global_patient_id", globalPatientId || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientListingApiResponse, patientListingApiError]);





  /**
   * @PatientDetailsById
   */
  const { data: patientDetailApiResponse, error: patientDetailApiError, mutate: getPatientById, removeErrorAndData: clearPatientDetails } =
    useFetch<PatientDetailType>(APIEndpoints.GET_PATIENT_DETAILS_BY_ID, {}, {}, false);

  useEffect(() => {
    if (typeof globalPatientId === "number" && prevPatientId !== globalPatientId) {
      resetPatientDetails(setValue, globalPatientId);
      setPrevPatientId(0);

      if (globalPatientId !== 0 && patientListingApiResponse) {
        setLoader();
        clearPatientDetails();
        getPatientById(null, null, { global_patient_id: globalPatientId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalPatientId, patientListingApiResponse]);

  useEffect(() => {
    if (patientDetailApiResponse || patientDetailApiError) {
      hideLoader();
      if (patientDetailApiResponse) {
        setPrevPatientId(patientDetailApiResponse.data.patient_info.id);
        addPatientDetails(setValue, patientDetailApiResponse);
        trigger(["patient_attributes", "insurances_attributes"]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetailApiResponse, patientDetailApiError]);

  //:- Filling missing data into parent object
  useEffect(() => {
    if (practiceId) {
      const practiceData = practiceDropdownApiRes?.data.find((value) => value.id === practiceId);
      if (practiceData) setValue("practice_name", `${practiceData.name} - ${practiceData.address}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceId]);

  useEffect(() => {
    if (doctorId) {
      const doctorObj = globalDoctorsApiResponse?.data.find((value) => value.id === doctorId);
      if (doctorObj) {
        setValue("doctor_fullname", doctorObj.fullname);
        setValue("doctor_firstlastname", doctorObj.first_last_name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorId]);




  return (
    <div className={`${!isVisible ? 'd-none' : ''}`}>
      <div className="card mb-3">
        <div className="card-body">
          <Controller
            name="doctor_attributes.practice_id"
            control={control}
            defaultValue={0}
            render={({ field, fieldState: { error } }) => (
              <HubInputField
                inputId="doctor_attributes.practice_id"
                inputLabel="Select a Practice"
                isRequired={true}
                isRowBased={true}
                field={field}
                error={error}
                isDropdown={true}
                isSearchable={false}
                dropdownData={
                  (practiceDropdownApiRes && practiceDropdownApiRes.data && practiceDropdownApiRes.data.length > 0 ?
                    practiceDropdownApiRes.data.map((practice) => ({ label:  practice.address ? `${practice.name.charAt(0).toUpperCase() + practice.name.slice(1)} - ${practice.address}` : practice.name , value: practice.id }))
                    : []
                  ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                }
              />
            )}
          />
          {practiceId ? (
            <Controller
              name="doctor_attributes.global_doctor_id"
              control={control}
              defaultValue={0}
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="doctor_attributes.global_doctor_id"
                  inputLabel="Administering Provider"
                  isRequired={true}
                  isRowBased={true}
                  field={field}
                  error={error}
                  isDropdown={true}
                  isSearchable={false}
                  dropdownData={
                    (globalDoctorsApiResponse && globalDoctorsApiResponse.data && globalDoctorsApiResponse.data.length > 0 ?
                      globalDoctorsApiResponse.data.map((provider) => ({ value: provider.id, label: provider.fullname }))
                      : []
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
          ) : null}
          {doctorId ? (
            <Controller
              name="patient_attributes.global_patient_id"
              control={control}
              defaultValue={0}
              render={({ field, fieldState: { error } }) => (
                <HubInputField
                  inputId="patient_attributes.global_patient_id"
                  inputPlaceholder="(New Patient)..."
                  isRequired={true}
                  isRowBased={true}
                  field={field}
                  error={error}
                  inputLabel="Select a Patient"
                  isDropdown={true}
                  isSearchable={true}
                  dropdownData={
                    (patientListingApiResponse && patientListingApiResponse.data && patientListingApiResponse.data.length > 0 ?
                      [{ value: 0, label: `(New Patient)` }, ...patientListingApiResponse.data.map((patient) => ({ value: patient.id, label: patient.fullname }))]
                      : [{ value: 0, label: `(New Patient)` }]
                    ) as unknown as OptionsOrGroups<string, GroupBase<string>>
                  }
                />
              )}
            />
          ) : null}
        </div>
      </div>

      {doctorId ? (
        <>
          <AddCasePatientInformation control={control} watch={watch} getValues={getValues} setValue={setValue} />
          <AddCasePatientAddress control={control} watch={watch} getValues={getValues} setValue={setValue} />
          <AddCasePatientInsurances control={control} watch={watch} getValues={getValues} setValue={setValue} />
        </>
      ) : null}
    </div>
  );
};

export default CaseStepTwo;
