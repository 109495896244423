
import { FC, useContext, useEffect, useState } from "react"

import useFetch from "src/hooks/useFetch";
import { AuthContext } from "src/context/authContext";
import { LoaderContext } from "src/context/loaderContext";
import { ToastContext } from "src/context/toastContext";
import { downloadExcelFile } from "src/helpers/downloadFile";

import { APIEndpoints } from "src/types/apiTypes";
import { DownloadReportsResponseTypeApi, StatsReportProps } from "./types";
import { ToastTypes } from "src/types";
import { MANUFACTURER_USER_REPORTS, REP_USER_REPORTS } from "src/constants/manufacturer";



const StatsReports: FC<StatsReportProps> = ({ getValues }): JSX.Element => {
  const [enabledButton, setEnabledButton] = useState<boolean>(false);

  const { userData } = useContext(AuthContext)
  const { showToast } = useContext(ToastContext);
  const { setLoader, hideLoader } = useContext(LoaderContext);




  const {
    data: downloadReportsData, error: downloadReportsError, mutate: downloadRequestForm,
    removeErrorAndData: clearRequestFileData
  } = useFetch<DownloadReportsResponseTypeApi>(APIEndpoints.MANUFACTURE_STATISTICS_REPORT_DOWNLOAD);

  useEffect(() => {
    if (downloadReportsData || downloadReportsError) {
      if (downloadReportsData) {
        const { response, report_name } = downloadReportsData;
        downloadExcelFile(response, `${report_name}.xlsx`);
        showToast(["Report downloaded successfully"], ToastTypes.SUCCESS)
        hideLoader()
      } else hideLoader()

      setEnabledButton(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportsData, downloadReportsError]);

  const downloadReport = (reportName: string) => {
    if (getValues) {
      let params = {
        'start_date': getValues().start_date,
        'end_date': getValues().end_date,
        'report_name': reportName,
        ...(userData && userData.ref_type === "rep" ? { user_id: userData.id } : {})
      }
      if (downloadReportsData || downloadReportsError) clearRequestFileData();
      setLoader();
      setEnabledButton(true)
      downloadRequestForm({}, {}, params);
    }
  }



  return (
    <div className="row g-2">
      {enabledButton ? (
        <div className="col">
          <p className="text-dark h5">
            <span className="spinner-border spinner-border-sm" style={{ width: 20, height: 20 }} aria-hidden="true"></span>{' '}
            <i>Fetching Report</i>
          </p>
        </div>
      ) : (
        <>
          {userData && (userData.ref_type === 'Manufacturer') &&
            MANUFACTURER_USER_REPORTS.map((report, index) => (
              <div className="col-6 col-sm-3" key={index}>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => downloadReport(report.value)}
                >
                  {report.label}
                </button>
              </div>
            ))
          }

          {userData && (userData.ref_type === 'rep') && 
            REP_USER_REPORTS.map((report, index) => (
              <div className="col-6 col-sm-3" key={index}>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => downloadReport(report.value)}
                >
                  {report.label}
                </button>
              </div>
            ))
          }
        </>
      )}
    </div>
  );
};

export default StatsReports;
