import { UseFormGetValues } from "react-hook-form";

import { UserType } from "src/types/users";
import { AddCaseProps, CaseAttributesProps, CaseDoctorProps, InsuranceAttributesProps, PatientAddress, PatientAttributes, PatientPhoneNumber, PrescriptionAttributesType, ProcedureProps } from "src/pages/addCase/types";
import { CaseCloneResponseType } from "src/pages/cloneCase/types";
import { dataSitofCare } from "src/constants/contact";
import { format, parse } from "date-fns";



export function populatingCloneCase({ data }: CaseCloneResponseType, userData: UserType, getValues:  UseFormGetValues<AddCaseProps>): AddCaseProps {
  const caseFormData: AddCaseProps = {} as AddCaseProps;

  if (data.case) {
    caseFormData.mos = "online";
    caseFormData.user_id = userData ? userData.id : 0;
    caseFormData.creator_name = userData ? userData.name : '';
    caseFormData.submission_source = data.case.submission_source || "provider";
    caseFormData.category = data.case.category || '';

    caseFormData.prescription_attributes = {} as PrescriptionAttributesType;
    caseFormData.prescription_attributes.drug_id = data.case.drug_id || getValues("prescription_attributes.drug_id");
  }

  caseFormData.procedure_attributes = {}  as ProcedureProps;
  if (data.procedure) {
    caseFormData.procedure_attributes.cpt_code = data.procedure[0].cpt_code;
    caseFormData.procedure_attributes.secondary_cpt_code = data.procedure && data.procedure[1] ? data.procedure[1].cpt_code : "";
  }

  if (data.soc) {
    const isActualShipTo = dataSitofCare.find((soc) => soc?.value === data.soc[0]?.value);
    caseFormData.case_attributes = {} as CaseAttributesProps;
    if (isActualShipTo) {
      caseFormData.case_attributes.ship_to = data.soc[0].value
    } else {
      caseFormData.case_attributes.ship_to = "other"
      caseFormData.case_attributes.other_ship_to = data.soc[0]?.value
    }
  }

  caseFormData.prescription_attributes = {} as PrescriptionAttributesType;

  if (data.prescription) {
    const diagnosis = data.prescription.diagnosis_code;
    caseFormData.prescription_attributes.diagnosis_code = diagnosis !== "" ? diagnosis.split(",") : [];
    caseFormData.prescription_attributes.note = data.prescription.note;
    caseFormData.prescription_attributes.direction = data.prescription.direction;

    const drug_name = data.prescription.product;
    caseFormData.prescription_attributes.drug_name = drug_name;
  }

  if (data.prescription) {
    caseFormData.prescription_attributes.drug_id = data.prescription.drug_id;
    caseFormData.prescription_attributes.qty = Number(data.prescription.qty) || 0;
  }

  if (data.doctor) {
    caseFormData.doctor_firstlastname = `${data.doctor.firstname} ${data.doctor.lastname}`;
    caseFormData.doctor_fullname =
      `${data.doctor.firstname} ${data.doctor.middlename || ""} ${data.doctor.lastname}`;

    caseFormData.doctor_attributes = {
      practice_id: data.doctor.practice_id,
      global_doctor_id: data.doctor.global_doctor_id,
    } as CaseDoctorProps;
  }

  if (data.practice || data.practice_address) {
    caseFormData.practice_name = `${data.practice.name} - ${data.practice_address.full_address}`;
  }

  if (data.patient) {
    const { patient } = data;

    caseFormData.patient_firstlastname = `${patient.firstname} ${patient.lastname}`;
    caseFormData.patient_fullname = `${patient.firstname} ${patient.middlename || ""} ${patient.lastname}`;
    caseFormData.patient_dob = patient.dob;

    caseFormData.patient_attributes = {
      global_patient_id: patient.global_patient_id,
      title: patient.title || "",
      firstname: patient.firstname,
      middlename: patient.middlename,
      lastname: patient.lastname,
      gender: patient.gender,
      dob: format(parse(patient.dob, "yyyyy-MM-dd", new Date()), "MM/dd/yyyy"),
      ssn: patient.ssn,
      best_time_to_contact: patient.best_time_to_contact || "",
      patient_email_id: null,
      patient_email: data.email_address ? data.email_address.email : "",
    } as PatientAttributes;
  }

  if (data.patient_phone_numbers && data.patient_phone_numbers.length > 0) {
    caseFormData.patient_attributes.phone_numbers = [];

    data.patient_phone_numbers.forEach((phone, index) => {
      caseFormData.patient_attributes.phone_numbers[index] = {} as PatientPhoneNumber;
      caseFormData.patient_attributes.phone_numbers[index].number = phone.number.toString();
      caseFormData.patient_attributes.phone_numbers[index].phone_type = phone.phone_type || "cell";
      caseFormData.patient_attributes.phone_numbers[index].is_ok_to_leave_message = phone.is_ok_to_leave_message;
      caseFormData.patient_attributes.phone_numbers[index].is_preferred = phone.is_preferred;
      caseFormData.patient_attributes.phone_numbers[index].id = phone.phone_number_id;
    });
  }

  caseFormData.patient_attributes.address = {
    address_name: "home",
  } as PatientAddress;
  if (data.patient_address) {
    caseFormData.patient_attributes.address.street = data.patient_address.street || "";
    caseFormData.patient_attributes.address.suite = data.patient_address.suite || "";
    caseFormData.patient_attributes.address.city = data.patient_address.city || "";
    caseFormData.patient_attributes.address.state = data.patient_address.state || "";
    caseFormData.patient_attributes.address.zip = data.patient_address.zip || "";
  }

  if (data.patient_entities_address) {
    caseFormData.patient_attributes.address.address_name = data.patient_entities_address.address_name || "";
  }

  caseFormData.insurances_attributes = [
    {} as InsuranceAttributesProps,
  ];
  if (data.insurances && data.insurances.length > 0) {
    data.insurances.forEach((insurance, index) => {
      if (caseFormData.insurances_attributes) {
        caseFormData.insurances_attributes[index] = {} as InsuranceAttributesProps;
        caseFormData.insurances_attributes[index].insurance_name = insurance.insurance_name;
        caseFormData.insurances_attributes[index].relation_to_patient = insurance.relation_to_patient;
        caseFormData.insurances_attributes[index].relation_firstname = insurance.relation_firstname || "";
        caseFormData.insurances_attributes[index].relation_middlename = insurance.relation_middlename || "";
        caseFormData.insurances_attributes[index].relation_lastname = insurance.relation_lastname || "";
        caseFormData.insurances_attributes[index].policy = insurance.policy || "";
        caseFormData.insurances_attributes[index].group_number = insurance.group_number || "";
        caseFormData.insurances_attributes[index].insurance_number = insurance.insurance_number || "";
        caseFormData.insurances_attributes[index].member_id = insurance.member_id || "";
        caseFormData.insurances_attributes[index].bin = insurance.bin || "";
        caseFormData.insurances_attributes[index].pcn = insurance.pcn || "";
        caseFormData.insurances_attributes[index].rx_group = insurance.rx_group || "";
        caseFormData.insurances_attributes[index].global_insurance_id = insurance.global_insurance_id ? insurance.global_insurance_id.toString() : null;
        caseFormData.insurances_attributes[index].is_primary_insurance = index === 0;
        caseFormData.insurances_attributes[index].insurance_priority = index;
      }
    });
  }

  return caseFormData;
}
