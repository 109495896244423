export const REP_USER_REPORTS = [
  {
    value: "provider_list_field_view",
    label: "Provider List Field View",
  },
  {
    value: "practice_list_field_view",
    label: "Practice List Field View",
  },
  {
    value: "full_report_field",
    label: "Full Report Field",
  },
  {
    value: "med_pa_report_field",
    label: "Med PA Report Field",
  },
]

export const MANUFACTURER_USER_REPORTS = [
  // ...REP_USER_REPORTS,
  {
    value: "provider_list_field_view",
    label: "Provider List Field View",
  },
  {
    value: "provider_list_office_view",
    label: "Provider List Office View",
  },
  {
    value: "practice_list_field_view",
    label: "Practice List Field View",
  },
  {
    value: "practice_list_office_view",
    label: "Practice List Office View",
  },
  {
    value: "full_report_office",
    label: "Full Report Office",
  },
  {
    value: "full_report_field",
    label: "Full Report Field",
  },
  {
    value: "med_pa_report_office",
    label: "Med PA Report Office",
  },
  {
    value: "med_pa_report_field",
    label: "Med PA Report Field",
  },
]
